<template>
  <div :class="isMobile ? 'about-m' : 'about'">
    <div class="word">
      <div class="word-title">关于我们</div>
      <div class="word-gray"></div>
      <div class="word-text">领先的科技公司，公司核心成员皆获得博士学位，技术顾问团队包括国内高校的教授和博士生导师。</div>
    </div>
    <div class="content">
      <img class="contentImg" :src="imgUrl.company" />
      <div class="contentText">
        <div class="title">
          艾感科技(广东)有限公司是一家专注于仿生嗅觉芯片研发和生产的国家高新技术企业。
        </div>
        <p>
          公司核心团队来源于香港科技大学知名教授课题组负责人及骨干。联合创始人获得2022年腾讯“科学探索奖”。
        </p>
        <p>
          基于科研团队在纳米基材、气敏材料和芯片器件结构等方面20多年的积累，成功开发出纳米蜂巢（NHC）第三代半导体气体传感器，具有低功耗、小型化、和易于阵列化及超大规模集成等优势。目前公司产品包括仿生嗅觉芯片、模组、设备方案等。生产方面摒弃硅基路线，节省光刻等高成本步骤，产线成本仅有现有第二代mems半导体产线成本的10%。
        </p>
        <p>
          公司2021年和2023年分别完成天使轮和Pre-A 轮融资，累计融资约2500万元人民币。投资机构包括HKX（由沈南鹏，李泽湘和陈冠华联合成立），立创商城（国内最大元器件电商）和清水湾基金等知名机构。
        </p>
      </div>
    </div>
    <div class="mission">
      <div class="word-title">企业文化</div>
      <div class="word-gray"></div>
      <div class="word-text">CORPORATE CULTURE</div>
      <div class="mission-content">
        <el-row>
          <el-col :span="isMobile ? 24 : 10">
            <div :class="isMobile ? 'mission-one bg01' : 'mission-one bg01 mission-one-left'">
              <div class="one-title">VALUE</div>
              <div class="one-title-cn">价值观</div>
              <div class="one-text">客户第一，奋斗为本，追求卓越</div>
            </div>
          </el-col>
          <el-col :span="isMobile ? 24 : 10">
            <div :class="isMobile ? 'mission-one bg02' : 'mission-one bg02 mission-one-left'">
              <div class="one-title">MISSION</div>
              <div class="one-title-cn">使命</div>
              <div class="one-text">做世界上最好的嗅觉传感器</div>
            </div>
          </el-col>
          <el-col :span="4" v-show="!isMobile">
            <div class="mission-blue"></div>
          </el-col>
        </el-row>
        <div class="mission-one bg03">
          <div class="one-title">VISION</div>
          <div class="one-title-cn">愿景</div>
          <div class="one-text">让气味信息可以永久保留</div>
        </div>
      </div>
    </div>
    <div class="connect">
      <div class="word-title">联系我们</div>
      <div class="connect-content">
        <el-row class="connect-row">
          <el-col :span="isMobile ? 24 : 12">
            <div class="connect-one connect-one-left">
              <div class="connect-one-title">
                <img :src="imgUrl.bluePhone" />
                电话咨询
              </div>
              <div class="connect-one-text">如果您对我们的产品感兴趣，想深入了解、体验产品，请联系我们的产品咨询团队：</div>
              <div class="connect-one-text">联系电话：13318386725（王经理）</div>
            </div>
          </el-col>
          <el-col :span="isMobile ? 24 : 12" >
            <div class="connect-one">
              <div class="connect-one-title">
                <img :src="imgUrl.blueMap" />
                公司地址
              </div>
              <div class="connect-one-text">地址：广东省佛山市禅城区张槎街道华兴路22号顺盈嘉智能科技园C座八楼C801</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="isMobile ? 24 : 12">
            <div class="connect-one connect-one-left">
              <div class="connect-one-title">
                <img :src="imgUrl.blueEmail" />
                邮箱邮编
              </div>
              <div class="connect-one-text">
                电子邮箱：aigankejizhouzong@aisensing.cn<br />
                邮编：528000
              </div>
            </div>
          </el-col>
          <el-col :span="isMobile ? 24 : 12" >
            <div class="connect-one">
              <div class="connect-one-title">
                <img :src="imgUrl.blueChat" />
                在线留言
              </div>
              <div class="connect-one-text">
                姓名<el-input class="inputInline" width="30%" v-model="username"></el-input> 
                电话<el-input class="inputInline" width="30%" v-model="userphone"></el-input><br />
                内容<el-input class="textareaInline" type="textarea" :rows="4" v-model="userText"></el-input>
                <span class="submitBox"><el-button type="primary" class="buttonInline" @click="submit">提交</el-button></span>
              </div>
            </div>
          </el-col>
        </el-row>
        <div id="map" style="height: 282px;" class="tMapBox"></div>
        <div class="connect-bottom">
            <div class="connect-bottom-title">艾感科技（广东）有限公司</div>
            <div class="connect-bottom-text">佛山市禅城区张槎街道华兴路22号顺盈嘉智能科技园C座八楼C801</div>
        </div>
      </div>
    </div>
    <Theme bg="#f3f4f9"></Theme>
  </div>
</template>

<script>
  import imgUrl from "@/utils/imgUrl"
  import Theme from "@/components/Theme.vue"
  import * as L from "leaflet";
  import "leaflet/dist/leaflet.css";
  import { request } from "./../../request/request"
  import { isMobileNumber } from "./../../utils/utils"

  // 解决 leaflet 默认 maker 无法显示的问题
  let DefaultIcon = L.icon({
    // iconUrl: require("images/ico_xm_0-50.png"),
    iconUrl: require("@/assets/icon/ico_dw.png"),
    iconSize: [23, 30], // 加上，fixed marker在放缩5 时偏差比较大的问题
    iconAnchor: [3, 40],
    popupAnchor: [0, -50]
  });
  L.Marker.prototype.options.icon = DefaultIcon;


  export default {
    name: 'About',
    components: {
      "Theme": Theme, //公司概况
    },
    data() {
      return {
        imgUrl,
        isMobile: window.innerWidth < 780,
        username: "",
        userphone: "",
        userText: "",
      }
    },
    props: {
      msg: String
    },
    mounted() {
      window.addEventListener("resize", this.initFunc)
      this.setmap()
    },
    destroyed() {
      window.removeEventListener("resize", this.initFunc)
    },
    methods: {
      setmap(){
        var map = L.map('map').setView(L.latLng(23.0435205078125, 113.07627766927084), 17);
        L.tileLayer('/tile/{z}/{x}/{y}.png', {
          maxZoom: 19,
          maxNaticeZoom: 22
        }).addTo(map);
        var marker = L.marker([23.0435205078125, 113.07627766927084]).addTo(map);
        marker.bindPopup("<b class='map-title'>艾感科技（广东）有限公司</b><br><div class='map-subtitle'>佛山市禅城区张槎街道华兴路22号顺盈嘉智能科技园C座八楼C801</div>",
        {maxWidth: 200}).openPopup();
      },
      submit() {
        if (this.isMobile) {
          if(this.username && this.userphone && this.userText) {
            isMobileNumber(this.userphone) ? this.submitRequest() : this.$dialog.alert({
              message: '请输入正确的手机号码',
            })
          } else {
            this.$dialog.alert({
                message: '请输入姓名和电话和留言后再提交',
            })
          }
        } else {
          if(this.username && this.userphone && this.userText) {
            isMobileNumber(this.userphone) ? this.submitRequest() : this.$message({
              message: '请输入正确的手机号码!',
              type: 'warning'
            });
          } else {
            this.$message({
              message: '请输入姓名和电话和留言后再提交!',
              type: 'warning'
            });
          }
        }
      },
      submitRequest(){
        request({
          url: "/device/airMessages",
          method: "post",
          data: {
            userName: this.username,
            telephone: this.userphone,
            remark: this.userText
          }
        }).then(res => {
          if(res.data.successful){
            if (this.isMobile) {
              this.$dialog.alert({
                message: '提交成功!',
              })
            }else{
              this.$message({
                message: '提交成功！',
                type: 'success'
              });
            }
          }
        }).catch((err) => {
          console.log("err", err)
        });
      },
      initFunc() {
        this.isMobile = window.innerWidth < 780
      }
    }
  }
</script>
  
  
  <style lang="less">
    .leaflet-bottom{
      opacity: 0;
      z-index: -1;
    }
    .el-input /deep/ .el-input__inner{
      border: none;
      height: 38px;
      line-height: 38px;
    }
    .el-textarea /deep/ .el-textarea__inner{
      border: none;
    }
    p{
      margin: 0;
    }
    .about{
      .word{
        .word-title{
          font-size: 26px;
          line-height: 36px;
          font-weight: bold;
          color: #333333;
          letter-spacing: 2px;
          margin: 60px 0 10px;
          text-align: center;
        }
        .word-gray{
          width: 80px;
          height: 2px;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 17px;
          line-height: 50px;
          color: #758292 ;
          text-align: center;
        }
      }
      .content{
        margin: 50px auto;
        max-width: 1000px;
        .contentImg{
          float: left;
          margin-right: 40px;
          margin-left: 8px;
        }
        .contentText{
          font-size: 14px;
          color: #515151;
          line-height: 27px;
          text-align: justify;
          text-indent: 32px;
          margin-right: 8px;
          .title{
            color: #4e6486;
            font-size: 18px;
            line-height: 30px;
            font-weight: bold;
          }
        }
      }
      .mission{
        background: #f3f4f9;
        padding: 50px 0;
        .word-title{
          font-size: 28px;
          margin-bottom: 10px;
          font-weight: bold;
          color: #1b1b1b;
          font-family: fantasy;
          text-align: center;
        }
        .word-gray{
          width: 80px;
          height: 2px;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 17px;
          color: #534f4e ;
          margin-bottom: 46px;
          margin-top: 10px;
          text-align: center;
        }
        .mission-content{
          max-width: 1000px;
          margin: 0 auto;
          .mission-one{
            background: #fff;
            position: relative;
            text-align: left;
            padding: 75px 40px 50px 88px;
            margin-bottom: 20px;
            .one-title{
              color: #0077fc;
              font-weight: bold;
              font-family: auto;
              font-size: 30px;
            }
            .one-title-cn{
              color: #242425;
              font-size: 24px;
              margin: 42px 0 5px;
            }
            .one-text{
              color: #404143;
              font-size: 18px;
            }
          }
          .mission-one-left{
            margin-right: 20px;
          }
          .mission-blue{
            background: #0177ff;
            width: 100%;
            height: 267px;
            background-image: url('../../assets/icon/ULTU.png');
            background-repeat: no-repeat;
            background-position: right;
          }
          .bg01{
            background-image: url('../../assets/icon/01.png');
            background-repeat: no-repeat;
            background-position-x: 8px;
            background-position-y: 50px;
          }
          .bg02{
            background-image: url('../../assets/icon/02.png');
            background-repeat: no-repeat;
            background-position-x: 8px;
            background-position-y: 50px;
          }
          .bg03{
            background-image: url('../../assets/icon/03.png');
            background-repeat: no-repeat;
            background-position-x: 8px;
            background-position-y: 50px;
          }
        }
        
      }
      .connect{
        padding: 50px 0 0;
        .connect-content{
          max-width: 1000px;
          margin: 0 auto;
          .connect-row{
            margin-bottom: 20px;
          }
        }
        .word-title{
          font-size: 28px;
          color: #1a1a1a;
          font-weight: bold;
          margin: 20px 0 50px;
          text-align: center;
        }
        .connect-one-left{
            margin-right: 20px;
        }
        .connect-one{
          text-align: left;
          background: #f3f4f9;
          padding: 36px 18px 7px 36px;
          min-height: 235px;
          .connect-one-title{
            color: #111312;
            font-size: 20px;
            margin-bottom: 24px;
            img{
              display: inline-block;
              vertical-align: middle;
              margin-right: 4px;
            }
          }
          .connect-one-text{
            color: #585453;
            line-height: 28px;
            margin-bottom: 28px;
            .inputInline{
              vertical-align: top;
              display: inline-block;
              width: 28%;
              margin-right: 30px;
              margin-left: 6px;
              margin-bottom: 16px;
              border: 1px solid #bab4b4;
              border-radius: 6px;
              height: 38px;
            }
            .textareaInline{
              vertical-align: top;
              display: inline-block;
              width: 70%;
              margin-right: 30px;
              margin-left: 6px;
              border: 1px solid #bab4b4;
              border-radius: 6px;
            }
            .buttonInline{
              vertical-align: bottom;
              font-size: 14px;
              background: #0177ff;
              padding: 10px 16px;
            }
          }
        }
        .connect-bottom{
          padding: 20px 16px 50px 0;
          box-sizing: border-box;
          background: #FFFFFF;
          width: 100%;
          .connect-bottom-title{
            font-size: 18px;
            color: #000;
          }
          .connect-bottom-text{
            font-size: 14px;
            color: #232323;
            margin-top: 10px;
          }
        }
      }
      .tMapBox{
        height: 278px;
        overflow: hidden;
        margin: 20px auto 0;
        .map-title{
          font-size: 15px;
          line-height: 30px;
        }
        .map-subtitle{
          font-size: 12px;
          line-height: 20px;
        }
      }

    }
    .about-m{
      .word{
        .word-title{
          font-size: 0.6rem;
          line-height: 1rem;
          font-weight: bold;
          color: #333333;
          letter-spacing: 0.04rem;
          margin: 1.2rem 0 0.2rem;
          text-align: center;
        }
        .word-gray{
          width: 1.6rem;
          height: 0.04rem;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 0.38rem;
          line-height: 0.8rem;
          color: #758292 ;
          text-align: center;
          padding: 0 0.2rem;
        }
      }
      .content{
        margin: 1rem auto;
        .contentImg{
          display: block;
          width: 90%;
          margin: 0 auto;
        }
        .contentText{
          width: 90%;
          margin: 0.3rem auto;
          font-size: 0.40rem;
          color: #515151;
          line-height: 0.7rem;
          text-align: left;
          text-indent: 0.8rem;
          .title{
            color: #4e6486;
            font-size: 0.5rem;
            line-height: 1.06rem;
          }
        }
      }
      .mission{
        background: #f3f4f9;
        padding: 1rem 0 0.4rem;
        .word-title{
          font-size: 0.6rem;
          margin-bottom: 0.1rem;
          font-weight: bold;
          color: #1b1b1b;
          font-family: fantasy;
          text-align: center;
        }
        .word-gray{
          width: 1.6rem;
          height: 0.04rem;
          background: #d6d6d6;
          margin: 0 auto;
        }
        .word-text{
          font-size: 0.34rem;
          color: #758292 ;
          margin-bottom: 1rem;
          margin-top: 0.1rem;
          text-align: center;
        }
        .mission-content{
          margin: 0 0.2rem;
          .mission-one{
            background: #fff;
            position: relative;
            text-align: left;
            padding: 1.8rem 1.6rem 1.2rem 1.8rem;
            margin-bottom: 0.5rem;
            .one-title{
              color: #0077fc;
              font-weight: bold;
              font-family: auto;
              font-size: 0.75rem;
            }
            .one-title-cn{
              color: #242425;
              font-size: 0.5rem;
              margin: 1rem 0 0.18rem;
            }
            .one-text{
              color: #404143;
              font-size: 0.4rem;
            }
          }
          .bg01{
            background-image: url('../../assets/icon/01.png');
            background-repeat: no-repeat;
            background-position-x: 0.2rem;
            background-position-y: 1.2rem;
          }
          .bg02{
            background-image: url('../../assets/icon/02.png');
            background-repeat: no-repeat;
            background-position-x: 0.2rem;
            background-position-y: 1.2rem;
          }
          .bg03{
            background-image: url('../../assets/icon/03.png');
            background-repeat: no-repeat;
            background-position-x: 0.2rem;
            background-position-y: 1.2rem;
          }
        }
        
      }
      .connect{
        padding: 1.2rem 0 0;
        .connect-content{
          margin: 0 auto;
          .connect-row{
            margin-bottom: 0.06rem;
          }
        }
        .word-title{
          font-size: 0.6rem;
          color: #1a1a1a;
          font-weight: bold;
          margin: 0rem 0 0.4rem;
          text-align: center;
        }
        .connect-one{
          text-align: left;
          background: #f3f4f9;
          padding: 0.8rem 0.8rem 0.2rem 0.8rem;
          margin: 0.2rem;
          .connect-one-title{
            color: #111312;
            font-size: 0.46rem;
            margin-bottom: 0.5rem;
            img{
              display: inline-block;
              vertical-align: middle;
              margin-right: 0.1rem;
            }
          }
          .connect-one-text{
            color: #585453;
            line-height: 0.7rem;
            margin-bottom: 0.7rem;
            font-size: 0.4rem;
            .inputInline{
              display: inline-block;
              width: 85%;
              margin-left: 0.3rem;
              margin-bottom: 0.4rem;
            }
            .textareaInline{
              vertical-align: top;
              display: inline-block;
              width: 85%;
              margin-left: 0.3rem;
            }
            .submitBox{
              display: block;
              width: 100%;
              text-align: right;
            }
            .buttonInline{
              font-size: 0.36rem;
              background: #0177ff;
              padding: 0.24rem 0.4rem;
              margin-top: 0.4rem;
            }
          }
        }
        .connect-bottom{
          padding: 0.66rem 0.2rem 1.6rem 0.2rem;
          box-sizing: border-box;
          background: #FFFFFF;
          width: 100%;
          .connect-bottom-title{
            font-size: 0.46rem;
            color: #000;
          }
          .connect-bottom-text{
            font-size: 0.36rem;
            color: #232323;
            margin-top: 10px;
          }
        }
      }
      .tMapBox{
        height: 2.8rem;
        overflow: hidden;
        margin: 1rem auto 0;
        .map-title{
          font-size: 0.38rem;
        }
        .map-subtitle{
          font-size: 0.3rem;
        }
      }

    }
  </style>